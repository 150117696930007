<template>
  <r-block
    v-loading="loading"
    width="640px"
    no-padding
  >
    <r-title>Заполните информацию об акте</r-title>
    <r-block
      v-if="ready"
      no-padding
    >
      <header-comp
        :edit-mode="editMode"
        :source="source"
        :disabled="editMode && !hasChanges"
        @event-handler="eventHandler"
        @handle-change="hasChanges = true"
        @delete="deleteHandler"
        @export="exportAct"
      />

      <r-tabs
        @input="viewTab = $event"
      >
        <r-tab
          v-for="tab in tabs"
          :id="tab.label"
          :key="tab.label"
          :name="tab.title"
          :active="viewTab === tab"
        />
      </r-tabs>
      <attributes-list
        v-if="viewTab === 'main'"
        :is-editing="editMode"
        :model="filteredFields"
        :source="source"
        :related="related"
        @hasChanges="hasChanges = true"
      />
      <violation
        v-else-if="viewTab === 'violation'"
        ref="violation"
        :edit-mode="editMode"
        :source="source"
        @handle-change="hasChanges = true"
        @handle-update="loadAct"
      />
      <files-comments
        v-if="viewTab === 'filesComments'"
        :id="activeId"
        :source_id="source_id"
        column
        container
      />
    </r-block>
  </r-block>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { notifyFactory } from '@/utils'
import { getConfig, fields } from '../configs'
import { saveAs } from 'file-saver'

export default {
  components: {
    headerComp: () => import('./header'),
    violation: () => import('./violation'),
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list'),
    filesComments: () => import('@/components/files-comments/files-comments')
  },
  data() {
    return {
      loading: false,
      source_id: this.$store.state.bdd.actsUrl,
      editMode: false,
      ready: false,
      source: null,
      cacheSource: null,
      viewTab: 'main',
      tabs: [
        { title: 'Общая информация', label: 'main' },
        { title: 'Нарушения', label: 'violation' },
        { title: 'Файлы и комментарии', label: 'filesComments' }
      ],
      hasChanges: false,
      fields
    }
  },
  computed: {
    related() {
      return this.$store.state.bdd.actRelated || null
    },
    activeId() {
      return this.$store.state.bdd.act || null
    },
    actObjectFields() {
      return this.$store.state.bdd.actObjectFields || {}
    },
    filteredFields() {
      const actType = this.source.act_type?.name === 'Предписание'
        ? 'assignment'
        : 'violation'

      return this.fields?.filter(f => {
        return !f.only || f.only === actType
      })?.map(f => {
        if (typeof f.title === 'string') {
          return f
        } else {
          return {
            ...f,
            title: f.title?.[actType]
          }
        }
      })
    }
  },
  watch: {
    hasChanges(val) {
      if (val) {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      }
    }
  },
  created() {
    this.loadAct()
  },
  methods: {
    async loadAct() {
      if (!this.activeId) return

      this.loading = true

      const config = getConfig(this.activeId)
      const url = `objectInfo/${this.source_id}?config=${JSON.stringify(config)}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const parsed = this.parseData(data)
        this.source = parsed
        this.cacheSource = cloneDeep(this.source)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
        this.ready = true
      }
    },
    parseData(data) {
      const item = Object.values(data)?.[0]

      this.fields.forEach(f => {
        const reflection = this.actObjectFields[f.model]?.reflection

        if (reflection?.type === 'has_many_through') {
          item[f.model] = item[f.model]?.map(v => v.id)
        }
      })

      return item
    },
    eventHandler(event) {
      switch (event) {
        case 'edit':
          if (!this.editMode) {
            this.editMode = true
          } else {
            this.save()
          }
          break
        case 'cancel':
          this.source = cloneDeep(this.cacheSource)
          this.editMode = false
          break
        case 'export':
          break
      }
    },
    async save() {
      if (!this.source.id) return

      this.loading = true

      try {
        const item = this.getData()

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data: item
        })
        await this.updateViolations()

        const message = 'Сохранение выполнено успешно'

        this.$notify(notifyFactory('succcess', message))

        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateActs',
          value: true
        })
        this.editMode = false
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async updateViolations() {
      try {
        const init = this.source?.act_violations
        const current = this.$refs?.violation?.violations
        const changed = current?.filter(c => {
          const initItem = init?.find(v => v.id === c.id)
          return c.norm_doc_requirements !== initItem.norm_doc_requirements
        })

        if (changed?.length) {
          await Promise.all(
            changed.map(async({ id, norm_doc_requirements }) => {
              await this.$store.dispatch('PUT_REQUEST', {
                url: 'objectInfo/public.act_violations',
                data: {
                  id,
                  norm_doc_requirements
                }
              })
            })
          )
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    getData() {
      const item = {
        id: this.source.id,
        eliminated: this.source.eliminated
      }

      this.fields.forEach(f => {
        const reflection = this.actObjectFields[f.model]?.reflection

        if (reflection?.type === 'has_many_through') {
          const { foreign_key, through } = reflection
          const throughItems = this.cacheSource[through]
          const changed = this.source[f.model]
          const deleted = throughItems?.filter(th => {
            return !changed?.find(ch => ch === th[foreign_key])
          })?.map(th => th.id)
          const added = changed?.filter(cs => {
            return !throughItems?.find(ti => ti[foreign_key] === cs)
          })

          const value = []

          value.push(...added?.map(v => {
            return {
              [foreign_key]: v,
              act_id: this.source.id
            }
          }))
          value.push(...deleted?.map(v => {
            return {
              id: v,
              disabled: true
            }
          }))

          item[through] = value
        } else {
          if (this.source[f.model] !== this.cacheSource[f.model]) {
            item[f.model] = this.source[f.model] || null
          }
        }
      })

      return item
    },
    async deleteHandler() {
      this.loading = true
      const { source, source_id, $store } = this

      try {
        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: { id: source.id, disabled: true }
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateActs',
          value: true
        })
        $store.commit('CLOSE_MODAL_WINDOW')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async exportAct() {
      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_BLOB_REQUEST', {
          url: `act?format=export&id=${this.activeId}`
        })

        saveAs(data, 'act')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
